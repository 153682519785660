import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
  hasAdminAccess: (state: MainState) => {
    return state.profile && state.profile.role == 0;
  },
  hasSuperUserAccess: (state: MainState) => {
    return state.profile && state.profile.role == 1;
  },
  loginError: (state: MainState) => state.logInError,
  dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
  profile: (state: MainState) => state.profile,
  token: (state: MainState) => state.token,
  isLoggedIn: (state: MainState) => state.isLoggedIn,
  firstNotification: (state: MainState) =>
    state.notifications.length > 0 && state.notifications[0],
};

const { read } = getStoreAccessors<MainState, State>('');

export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readHasSuperUserAccess = read(getters.hasSuperUserAccess);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readToken = read(getters.token);
export const readProfile = read(getters.profile);
export const readFirstNotification = read(getters.firstNotification);
