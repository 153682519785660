import { IFilter } from '@/interfaces';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { FilterState } from './state';

export const mutations = {
  setMyFilters(state: FilterState, payload: IFilter[]) {
    state.myFilters = payload;
  },
  setMyFilter(state: FilterState, payload: IFilter) {
    const filters = state.myFilters.filter(
      (filter: IFilter) => filter.id !== payload.id,
    );
    filters.push(payload);
    state.myFilters = filters;
  },
  removeMyFilter(state: FilterState, payload: IFilter) {
    const filters = state.myFilters.filter(
      (filter: IFilter) => filter.id !== payload.id,
    );
    state.myFilters = filters;
  },
};

const { commit } = getStoreAccessors<FilterState, State>('');

export const commitSetMyFilters = commit(mutations.setMyFilters);
export const commitSetMyFilter = commit(mutations.setMyFilter);
export const commitRemoveMyFilter = commit(mutations.removeMyFilter);
