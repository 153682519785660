import { api } from '@/api';
import { IFilterCreate } from '@/interfaces';
import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { dispatchCheckApiError } from '../main/actions';
import {
  commitAddNotification,
  commitRemoveNotification,
} from '../main/mutations';
import { State } from '../state';
import {
  commitRemoveMyFilter,
  commitSetMyFilter,
  commitSetMyFilters,
} from './mutations';
import { FilterState } from './state';

type MainContext = ActionContext<FilterState, State>;

export const actions = {
  async actionGetMyFilters(context: MainContext, appId: number) {
    try {
      const response = await api.getFilters(
        context.rootState.main.token,
        appId,
      );
      if (response) {
        commitSetMyFilters(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionCreateMyFilter(
    context: MainContext,
    payload: { appId: number; data: IFilterCreate },
  ) {
    const notification = { content: 'Saving new filter', showProgress: true };
    try {
      commitAddNotification(context, notification);
      const response = await api.createFilter(
        context.rootState.main.token,
        payload.appId,
        payload.data,
      );
      commitSetMyFilter(context, response.data);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Filter successfully created',
        color: 'success',
      });
    } catch (error) {
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error creating filter',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionRemoveMyFilter(
    context: MainContext,
    payload: { appId: number; filterId: number },
  ) {
    const notification = { content: 'Removing filter', showProgress: true };
    try {
      commitAddNotification(context, notification);
      const response = await api.removeFilter(
        context.rootState.main.token,
        payload.appId,
        payload.filterId,
      );
      commitRemoveMyFilter(context, response.data);
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Filter successfully removed',
        color: 'success',
      });
    } catch (error) {
      commitRemoveNotification(context, notification);
      commitAddNotification(context, {
        content: 'Error removing filter',
        color: 'error',
      });
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
};

const { dispatch } = getStoreAccessors<FilterState, State>('');

export const dispatchGetMyFilters = dispatch(actions.actionGetMyFilters);
export const dispatchCreateMyFilter = dispatch(actions.actionCreateMyFilter);
export const dispatchRemoveMyFilter = dispatch(actions.actionRemoveMyFilter);
