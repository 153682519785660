import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { FilterState } from './state';

const defaultState: FilterState = {
  myFilters: [],
};

export const filterModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
