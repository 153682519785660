import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
  users: (state: AdminState) => state.users,
  user: (state: AdminState) => (userId: number) => {
    const filteredUsers = state.users.filter((user) => user.id === userId);
    if (filteredUsers.length > 0) {
      return { ...filteredUsers[0] };
    }
  },
  apps: (state: AdminState) => state.apps,
  app: (state: AdminState) => (appId: number) => {
    const filteredApps = state.apps.filter((app) => app.id === appId);
    if (filteredApps.length > 0) {
      return { ...filteredApps[0] };
    }
  },
  lastId: (state: AdminState) => state.lastId,
  selectedUsers: (state: AdminState) => state.selectedUsers,
  selectedApps: (state: AdminState) => state.selectedApps,
  activeCountryClusters: (state: AdminState) =>
    state.countryClusters.filter(
      (countryCluster) => countryCluster.is_active === true,
    ),
  countryClusters: (state: AdminState) => state.countryClusters,
  countryCluster: (state: AdminState) => (countryClusterId: number) => {
    const filteredClusters = state.countryClusters.filter(
      (countryCluster) => countryCluster.id === countryClusterId,
    );
    if (filteredClusters.length > 0) {
      return { ...filteredClusters[0] };
    }
  },
  myCountries: (state: AdminState) => state.myCountries,
  selectedCountries: (state: AdminState) => state.selectedCountries,
};

const { read } = getStoreAccessors<AdminState, State>('');

export const readUsers = read(getters.users);
export const readOneUser = read(getters.user);
export const readApps = read(getters.apps);
export const readOneApp = read(getters.app);
export const readLastId = read(getters.lastId);
export const readSelectedUsers = read(getters.selectedUsers);
export const readSelectedApps = read(getters.selectedApps);
export const readActiveCountryClusters = read(getters.activeCountryClusters);
export const readCountryClusters = read(getters.countryClusters);
export const readOneCountryCluster = read(getters.countryCluster);
export const readMyCountries = read(getters.myCountries);
export const readSelectedCountries = read(getters.selectedCountries);

export const TIMEZONES = [
  '(GMT+1:00) European Central Time (ECT)',
  '(GMT+2:00) East European Time (EET)',
  '(GMT+2:00) Egypt Standard Time (ART)',
  '(GMT+3:00) Eastern African Time (EAT)',
  '(GMT+3:30) Middle East Time (MET)',
  '(GMT+4:00) Near East Time (NET)',
  '(GMT+5:00) Pakistan Lahore Time (PLT)',
  '(GMT+5:30) India Standard Time (IST)',
  '(GMT+6:00) Bangladesh Standard Time (BST)',
  '(GMT+7:00) Vietnam Standard Time (VST)',
  '(GMT+8:00) China Taiwan Time (CTT)',
  '(GMT+9:00) Japan Standard Time (JST)',
  '(GMT+9:30) Australian Central Time (ACT)',
  '(GMT+10:00) Australian Eastern Time (AET)',
  '(GMT+11:00) Solomon Standard Time (SST)',
  '(GMT+12:00) New Zealand Standard Time (NST)',
  '(GMT-11:00) Midway Islands Time (MIT)',
  '(GMT-10:00) Hawaii Standard Time (HST)',
  '(GMT-9:00) Alaska Standard Time (AST)',
  '(GMT-8:00) Pacific Standard Time (PST)',
  '(GMT-7:00) Phoenix Standard Time (PNT)',
  '(GMT-7:00) Mountain Standard Time (MST)',
  '(GMT-6:00) Central Standard Time (CST)',
  '(GMT-5:00) Eastern Standard Time (EST)',
  '(GMT-5:00) Indiana Eastern Standard Time (IET)',
  '(GMT-4:00) Puerto Rico and US Virgin Islands Time (PRT)',
  '(GMT-3:30) Canada Newfoundland Time (CNT)',
  '(GMT-3:00) Argentina Standard Time (AGT)',
  '(GMT-2:00) Brazil Eastern Standard Time (BET)',
  '(GMT-1:00) Central African Time (CAT)',
];

export const COUNTRIES = [
  { name: 'Afghanistan', abbr: 'AF' },
  { name: 'Åland Islands', abbr: 'AX' },
  { name: 'Albania', abbr: 'AL' },
  { name: 'Algeria', abbr: 'DZ' },
  { name: 'American Samoa', abbr: 'AS' },
  { name: 'Andorra', abbr: 'AD' },
  { name: 'Angola', abbr: 'AO' },
  { name: 'Antigua and Barbuda', abbr: 'AG' },
  { name: 'Argentina', abbr: 'AR' },
  { name: 'Armenia', abbr: 'AM' },
  { name: 'Aruba', abbr: 'AW' },
  { name: 'Australia', abbr: 'AU' },
  { name: 'Austria', abbr: 'AT' },
  { name: 'Azerbaijan', abbr: 'AZ' },
  { name: 'Bahamas', abbr: 'BS' },
  { name: 'Bahrain', abbr: 'BH' },
  { name: 'Bangladesh', abbr: 'BD' },
  { name: 'Barbados', abbr: 'BB' },
  { name: 'Belarus', abbr: 'BY' },
  { name: 'Belgium', abbr: 'BE' },
  { name: 'Belize', abbr: 'BZ' },
  { name: 'Benin', abbr: 'BJ' },
  { name: 'Bermuda', abbr: 'BM' },
  { name: 'Bhutan', abbr: 'BT' },
  { name: 'Bolivia', abbr: 'BO' },
  { name: 'Bosnia and Herzegovina', abbr: 'BA' },
  { name: 'Botswana', abbr: 'BW' },
  { name: 'Brazil', abbr: 'BR' },
  { name: 'British Indian Ocean Territory', abbr: 'IO' },
  { name: 'Brunei', abbr: 'BN' },
  { name: 'Bulgaria', abbr: 'BG' },
  { name: 'Burkina Faso', abbr: 'BF' },
  { name: 'Burundi', abbr: 'BI' },
  { name: 'Cambodia', abbr: 'KH' },
  { name: 'Cameroon', abbr: 'CM' },
  { name: 'Canada', abbr: 'CA' },
  { name: 'Cape Verde', abbr: 'CV' },
  { name: 'Cayman Islands', abbr: 'KY' },
  { name: 'Central African Republic', abbr: 'CF' },
  { name: 'Chad', abbr: 'TD' },
  { name: 'Chile', abbr: 'CL' },
  { name: 'China', abbr: 'CN' },
  { name: 'Colombia', abbr: 'CO' },
  { name: 'Comoros', abbr: 'KM' },
  { name: 'Congo', abbr: 'CG' },
  { name: 'Congo, The Democratic Republic of the', abbr: 'CD' },
  { name: 'Costa Rica', abbr: 'CR' },
  { name: "Cote D'Ivoire", abbr: 'CI' },
  { name: 'Croatia', abbr: 'HR' },
  { name: 'Cuba', abbr: 'CU' },
  { name: 'Cyprus', abbr: 'CY' },
  { name: 'Czech Republic', abbr: 'CZ' },
  { name: 'Denmark', abbr: 'DK' },
  { name: 'Djibouti', abbr: 'DJ' },
  { name: 'Dominica', abbr: 'DM' },
  { name: 'Dominican Republic', abbr: 'DO' },
  { name: 'Ecuador', abbr: 'EC' },
  { name: 'Egypt', abbr: 'EG' },
  { name: 'El Salvador', abbr: 'SV' },
  { name: 'Equatorial Guinea', abbr: 'GQ' },
  { name: 'Eritrea', abbr: 'ER' },
  { name: 'Estonia', abbr: 'EE' },
  { name: 'Ethiopia', abbr: 'ET' },
  { name: 'Faroe Islands', abbr: 'FO' },
  { name: 'Fiji', abbr: 'FJ' },
  { name: 'Finland', abbr: 'FI' },
  { name: 'France', abbr: 'FR' },
  { name: 'French Guiana', abbr: 'GF' },
  { name: 'French Polynesia', abbr: 'PF' },
  { name: 'Gabon', abbr: 'GA' },
  { name: 'Gambia', abbr: 'GM' },
  { name: 'Georgia', abbr: 'GE' },
  { name: 'Germany', abbr: 'DE' },
  { name: 'Ghana', abbr: 'GH' },
  { name: 'Gibraltar', abbr: 'GI' },
  { name: 'Greece', abbr: 'GR' },
  { name: 'Greenland', abbr: 'GL' },
  { name: 'Grenada', abbr: 'GD' },
  { name: 'Guadeloupe', abbr: 'GP' },
  { name: 'Guam', abbr: 'GU' },
  { name: 'Guatemala', abbr: 'GT' },
  { name: 'Guernsey', abbr: 'GG' },
  { name: 'Guinea', abbr: 'GN' },
  { name: 'Guinea-Bissau', abbr: 'GW' },
  { name: 'Guyana', abbr: 'GY' },
  { name: 'Haiti', abbr: 'HT' },
  { name: 'Honduras', abbr: 'HN' },
  { name: 'Hong Kong', abbr: 'HK' },
  { name: 'Hungary', abbr: 'HU' },
  { name: 'Iceland', abbr: 'IS' },
  { name: 'India', abbr: 'IN' },
  { name: 'Indonesia', abbr: 'ID' },
  { name: 'Iran, Islamic Republic Of', abbr: 'IR' },
  { name: 'Iraq', abbr: 'IQ' },
  { name: 'Ireland', abbr: 'IE' },
  { name: 'Isle of Man', abbr: 'IM' },
  { name: 'Israel', abbr: 'IL' },
  { name: 'Italy', abbr: 'IT' },
  { name: 'Jamaica', abbr: 'JM' },
  { name: 'Japan', abbr: 'JP' },
  { name: 'Jersey', abbr: 'JE' },
  { name: 'Jordan', abbr: 'JO' },
  { name: 'Kazakhstan', abbr: 'KZ' },
  { name: 'Kenya', abbr: 'KE' },
  { name: 'Kiribati', abbr: 'KI' },
  { name: "Korea, Democratic People's Republic of", abbr: 'KP' },
  { name: 'Korea, Republic of', abbr: 'KR' },
  { name: 'Kuwait', abbr: 'KW' },
  { name: 'Kyrgyzstan', abbr: 'KG' },
  { name: 'Laos', abbr: 'LA' },
  { name: 'Latvia', abbr: 'LV' },
  { name: 'Lebanon', abbr: 'LB' },
  { name: 'Lesotho', abbr: 'LS' },
  { name: 'Liberia', abbr: 'LR' },
  { name: 'Libya', abbr: 'LY' },
  { name: 'Liechtenstein', abbr: 'LI' },
  { name: 'Lithuania', abbr: 'LT' },
  { name: 'Luxembourg', abbr: 'LU' },
  { name: 'Macao', abbr: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', abbr: 'MK' },
  { name: 'Madagascar', abbr: 'MG' },
  { name: 'Malawi', abbr: 'MW' },
  { name: 'Malaysia', abbr: 'MY' },
  { name: 'Maldives', abbr: 'MV' },
  { name: 'Mali', abbr: 'ML' },
  { name: 'Malta', abbr: 'MT' },
  { name: 'Marshall Islands', abbr: 'MH' },
  { name: 'Martinique', abbr: 'MQ' },
  { name: 'Mauritania', abbr: 'MR' },
  { name: 'Mauritius', abbr: 'MU' },
  { name: 'Mayotte', abbr: 'YT' },
  { name: 'Mexico', abbr: 'MX' },
  { name: 'Micronesia, Federated States of', abbr: 'FM' },
  { name: 'Moldova, Republic of', abbr: 'MD' },
  { name: 'Monaco', abbr: 'MC' },
  { name: 'Mongolia', abbr: 'MN' },
  { name: 'Morocco', abbr: 'MA' },
  { name: 'Mozambique', abbr: 'MZ' },
  { name: 'Myanmar', abbr: 'MM' },
  { name: 'Namibia', abbr: 'NA' },
  { name: 'Nepal', abbr: 'NP' },
  { name: 'Netherlands', abbr: 'NL' },
  { name: 'New Caledonia', abbr: 'NC' },
  { name: 'New Zealand', abbr: 'NZ' },
  { name: 'Nicaragua', abbr: 'NI' },
  { name: 'Niger', abbr: 'NE' },
  { name: 'Nigeria', abbr: 'NG' },
  { name: 'Northern Mariana Islands', abbr: 'MP' },
  { name: 'Norway', abbr: 'NO' },
  { name: 'Oman', abbr: 'OM' },
  { name: 'Pakistan', abbr: 'PK' },
  { name: 'Palau', abbr: 'PW' },
  { name: 'Palestine', abbr: 'PS' },
  { name: 'Panama', abbr: 'PA' },
  { name: 'Papua New Guinea', abbr: 'PG' },
  { name: 'Paraguay', abbr: 'PY' },
  { name: 'Peru', abbr: 'PE' },
  { name: 'Philippines', abbr: 'PH' },
  { name: 'Poland', abbr: 'PL' },
  { name: 'Portugal', abbr: 'PT' },
  { name: 'Puerto Rico', abbr: 'PR' },
  { name: 'Qatar', abbr: 'QA' },
  { name: 'Reunion', abbr: 'RE' },
  { name: 'Romania', abbr: 'RO' },
  { name: 'Russian', abbr: 'RU' },
  { name: 'Rwanda', abbr: 'RW' },
  { name: 'Saint Kitts and Nevis', abbr: 'KN' },
  { name: 'Saint Lucia', abbr: 'LC' },
  { name: 'Saint Vincent and the Grenadines', abbr: 'VC' },
  { name: 'Samoa', abbr: 'WS' },
  { name: 'San Marino', abbr: 'SM' },
  { name: 'Sao Tome and Principe', abbr: 'ST' },
  { name: 'Saudi Arabia', abbr: 'SA' },
  { name: 'Senegal', abbr: 'SN' },
  { name: 'Serbia and Montenegro', abbr: 'CS' },
  { name: 'Seychelles', abbr: 'SC' },
  { name: 'Sierra Leone', abbr: 'SL' },
  { name: 'Singapore', abbr: 'SG' },
  { name: 'Slovakia', abbr: 'SK' },
  { name: 'Slovenia', abbr: 'SI' },
  { name: 'Solomon Islands', abbr: 'SB' },
  { name: 'Somalia', abbr: 'SO' },
  { name: 'South Africa', abbr: 'ZA' },
  { name: 'Spain', abbr: 'ES' },
  { name: 'Sri Lanka', abbr: 'LK' },
  { name: 'Sudan', abbr: 'SD' },
  { name: 'Suriname', abbr: 'SR' },
  { name: 'Swaziland', abbr: 'SZ' },
  { name: 'Sweden', abbr: 'SE' },
  { name: 'Switzerland', abbr: 'CH' },
  { name: 'Syria', abbr: 'SY' },
  { name: 'Taiwan', abbr: 'TW' },
  { name: 'Tajikistan', abbr: 'TJ' },
  { name: 'Tanzania, United Republic of', abbr: 'TZ' },
  { name: 'Thailand', abbr: 'TH' },
  { name: 'Timor-Leste', abbr: 'TL' },
  { name: 'Togo', abbr: 'TG' },
  { name: 'Tonga', abbr: 'TO' },
  { name: 'Trinidad and Tobago', abbr: 'TT' },
  { name: 'Tunisia', abbr: 'TN' },
  { name: 'Turkey', abbr: 'TR' },
  { name: 'Turkmenistan', abbr: 'TM' },
  { name: 'Turks and Caicos Islands', abbr: 'TC' },
  { name: 'Tuvalu', abbr: 'TV' },
  { name: 'Uganda', abbr: 'UG' },
  { name: 'Ukraine', abbr: 'UA' },
  { name: 'United Arab Emirates', abbr: 'AE' },
  { name: 'United Kingdom', abbr: 'GB' },
  { name: 'United States', abbr: 'US' },
  { name: 'U.S. Outlying Islands', abbr: 'UM' },
  { name: 'U.S. Virgin Islands', abbr: 'VI' },
  { name: 'Uruguay', abbr: 'UY' },
  { name: 'Uzbekistan', abbr: 'UZ' },
  { name: 'Vanuatu', abbr: 'VU' },
  { name: 'Venezuela', abbr: 'VE' },
  { name: 'Vietnam', abbr: 'VN' },
  { name: 'Western Sahara', abbr: 'EH' },
  { name: 'Yemen', abbr: 'YE' },
  { name: 'Zambia', abbr: 'ZM' },
  { name: 'Zimbabwe', abbr: 'ZW' },
];
