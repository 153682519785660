import { MapsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
  mapClusters: (state: MapsState) => state.clusters,
  mapScans: (state: MapsState) => state.scans,
  mapScan: (state: MapsState) => (scanId: number) => {
    const items = state.scans.filter((scan) => scan.id === scanId);
    if (items.length > 0) {
      return { ...items[0] };
    }
  },
};

const { read } = getStoreAccessors<MapsState, State>('');

export const readMapClusters = read(getters.mapClusters);
export const readMapScans = read(getters.mapScans);
export const readScan = read(getters.mapScan);
