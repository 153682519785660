import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { FilterState } from './state';

export const getters = {
  myFilters: (state: FilterState) => state.myFilters,
};

const { read } = getStoreAccessors<FilterState, State>('');

export const readMyFilters = read(getters.myFilters);
